//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getPerfectList, _disable, _enable } from "@/api/auditPtf.js";
import { _getStarRatePlatformStarRateListByPage } from "@/api/province.js";
export default {
  data() {
    return {
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      formInline: {
        orderId: "",
        eaStatusCode: "",
      },
      input: "",
      tableData: [],
      options: [
        // {
        //   value: 2021,
        //   label: 2021,
        // },
        {
          value: 2022,
          label: 2022,
        },
        {
          value: 2023,
          label: 2023,
        },
        {
          value: 2024,
          label: 2024,
        },
        {
          value: 2025,
          label: 2025,
        },
        {
          value: 2026,
          label: 2026,
        },
        {
          value: 2027,
          label: 2027,
        },
        {
          value: 2028,
          label: 2028,
        },
        {
          value: 2029,
          label: 2029,
        },
        {
          value: 2030,
          label: 2030,
        },
      ],
      year: new Date().getFullYear(),
    };
  },
  created() {
    // result: '0'
    this.getPerfectList({
      currentNumber: this.current,
      pageSize: this.pageSize,
      year: this.year,
    });
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    // 查询
    // {result : '0', type:'1'}
    query() {
      let form = {};
      if (this.year) {
        form = {
          currentNumber: this.current,
          pageSize: this.pageSize,
          year: this.year,
        };
      } else {
        form = {
          currentNumber: this.current,
          pageSize: this.pageSize,
        };
      }

      this.getPerfectList(form);
    },
    // 列表数据接口
    getPerfectList(from) {
      _getStarRatePlatformStarRateListByPage(from).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    // 点击表格数据跳转
    tabClick(row) {
      console.log(row);
      this.$router.push({
        path: "/main/ptstarrating/starratinlist/order",
        query: { deptId: row.deptId },
      });
    },
    // 分页
    handleSizeChange(val) {
      this.current = 1;
      this.pageSize = val;
      let form = {};
      if (this.year) {
        form = {
          currentNumber: 1,
          pageSize: val,
          year: this.year,
        };
      } else {
        form = {
          currentNumber: 1,
          pageSize: val,
        };
      }
      this.getPerfectList(form);
    },
    handleCurrentChange(val) {
      this.current = val;

      let form = {};
      if (this.year) {
        form = {
          currentNumber: val,
          pageSize: this.pageSize,
          year: this.year,
        };
      } else {
        form = {
          currentNumber: val,
          pageSize: this.pageSize,
        };
      }

      this.getPerfectList(form);
    },
  },
};
